<template>
  <div>
    <!-- Loading -->
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>

    <!-- Header -->
    <main-header />
    <!--  -->
    <div class="container">
      <!-- Breadcrumb -->
      <div class="_flex _center">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb  bg-transparent tiny">
            <li class="breadcrumb-item"><a href="#">Accueil</a></li>
            <li class="breadcrumb-item active" aria-current="page">Oeuvres</li>
          </ol>
        </nav>
      </div>
    </div>
    <!--  -->
    <section class="d-none d-lg-block my-3 my-4-lg">
      <div class="container">
        <div class="row">
          <div
            class="col-6 col-md-4 col-lg-2 mb-5"
            @click="filterByPrice('<500')"
          >
            <div class="price_box">
              <div class="price_title">Moins de $500</div>
            </div>
          </div>
          <div
            class="col-6 col-md-4 col-lg-2 mb-5"
            @click="filterByPrice('500-5000')"
          >
            <div class="price_box">
              <div class="price_title">$500 - $5,000</div>
            </div>
          </div>
          <div
            class="col-6 col-md-4 col-lg-2 mb-5"
            @click="filterByPrice('5000-15000')"
          >
            <div class="price_box">
              <div class="price_title">$5,000 - $15,000</div>
            </div>
          </div>
          <div
            class="col-6 col-md-4 col-lg-2 mb-5"
            @click="filterByPrice('15000-50000')"
          >
            <div class="price_box">
              <div class="price_title">$15,000 - $50,000</div>
            </div>
          </div>
          <div
            class="col-6 col-md-4 col-lg-2 mb-5"
            @click="filterByPrice('50000-100000')"
          >
            <div class="price_box">
              <div class="price_title">$50,000 - $100,000</div>
            </div>
          </div>
          <div
            class="col-6 col-md-4 col-lg-2 mb-5"
            @click="filterByPrice('>100000')"
          >
            <div class="price_box">
              <div class="price_title">Plus de $100,000</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--  -->
    <div class="d-none d-lg-block p-2 border-bottom">
      <div class="container">
        <ul class="_flex _center body-1 text-muted list-unstyled">
          <li class="_flex mr-5 mr-10-lg mr-12-xl">
            Prix: <strong class="ml-1">{{ filteredPrice }}</strong>
          </li>
          <li class="_flex ">{{ count }} résultat{{ count > 1 ? "s" : "" }}</li>
        </ul>
      </div>
    </div>
    <!--  -->
    <!--  -->
    <div class="container px-15-lg px-20-xl">
      <div class=" my-10">
        <!-- <div class="row d-flex flex-row flex-wrap _center my-10"> -->
        <div class="my-10">
          <div class="card-columns" v-if="artworks.length">
            <!-- -->
            <artwork-card
              v-for="artwork in artworks"
              :artwork="artwork"
              :key="artwork.id"
            />
          </div>
          <template v-if="!loading && !artworks.length">
            <no-results />
          </template>
        </div>
      </div>
    </div>
    <!--  -->
    <main-footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: true,
    keyword: "",
  }),
  computed: {
    artworks() {
      return this.$store.getters.artworks.data || [];
    },
    count() {
      return this.$store.getters.artworks.count || 0;
    },
    filteredPrice() {
      switch (this.keyword) {
        case "<500":
          return "Moins de $500";
        case "500-5000":
          return "$500 - $5,000";
        case "5000-15000":
          return "$5,000 - $15,000";
        case "15000-50000":
          return "$15,000 - $50,000";
        case "50000-100000":
          return "$50,000 - $100,000";
        case ">100000":
          return "Plus de $100,000";
        default:
          return "";
      }
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal) {
        console.log(newVal);
        // make actions with newVal.page
      },
    },
  },
  methods: {
    filterByPrice(prix) {
      this.$router.push({
        path: "/filter-par-prix",
        query: {
          prix,
        },
      });

      this.keyword = prix;

      this.loading = true;
      this.$store
        .dispatch("fetchArtworksByPrice", this.keyword)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    initialize() {
      // When the user scrolls the page, execute myFunction

      window.onscroll = function() {
        scrollFunction();
      };

      //Get the button:
      var mybutton = document.getElementById("myBtn");

      // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position

      // When the user scrolls down 20px from the top of the document, show the button

      function scrollFunction() {
        if (
          document.body.scrollTop > 400 ||
          document.documentElement.scrollTop > 400
        ) {
          mybutton.style.display = "block";
        } else {
          mybutton.style.display = "none";
        }
      }
    },
  },
  mounted() {
    this.keyword = this.$route.query.prix;

    this.$store
      .dispatch("fetchArtworksByPrice", this.keyword)
      .then(() => {
        this.loading = false;
        console.log(this.artworks);
      })
      .catch(() => {
        this.loading = false;
      });

    this.initialize();
  },
};
</script>

<style></style>
